import React, {useEffect, useState} from 'react';
import { motion, useSpring, useAnimation } from "framer-motion";

//Mui
import styled from '@mui/system/styled';
import useTheme from '@mui/system/useTheme';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Button, { ButtonProps } from '@mui/material/Button';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

interface LearnMoreButtonProps extends ButtonProps{

}

export default function LearnMoreButton(props: LearnMoreButtonProps) {
    const [hovered, setHovered] = useState(false);
    const controls = useAnimation();

    useEffect(() => {

        if(hovered){
            controls.start('hovered');
        } else {
            controls.start('notHovered');
        }

    }, [hovered])
  return (
    <StyledContainer
    onMouseEnter={() => setHovered(true)}
    onMouseLeave={() => setHovered(false)}
    animate={controls}
    initial="notHovered"
    >
        <Link href={props.href}>
        <StyledButton 
        variant='outlined' 
        onClick={props.onClick} 
        color={props.color}
        sx={{
            ":hover": {
                backgroundColor: `${props.color}.main`,
                color: "white",
                transition: "all ease .5s"
            }
        }}
        >
            <Typography variant='button'>LEARN MORE</Typography>
            <StyledIconContainer
                animate={controls}
                initial="notHovered"
                transition={{ duration: 1, type: "spring", stiffness: 400, damping: 17 }}
                variants={{
                    hovered: {
                        fontSize: "28px",
                        x: 2
                    },
                    notHovered: {
                        fontSize: "28px",
                        x: 0
                    }
                }}
            >
                <KeyboardArrowRightIcon fontSize='inherit'/>
            </StyledIconContainer>
        </StyledButton>
        </Link>
    </StyledContainer>
  )
}

const StyledContainer = styled(motion.div)(({theme}) => ({
    display: "inline-flex",
    width:"auto !important",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "2rem",
}));

const StyledButton = styled(Button)(({theme}) => ({

}));

const StyledIconContainer = styled(motion.div)(({theme}) => ({
    fontSize: "28px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}));
