import React from 'react';
import Image from "next/image";
import { env } from '~/env.mjs';

//Mui
import styled from '@mui/system/styled';
import useMediaQuery from '@mui/material/useMediaQuery';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

//Images
import MainImage from "~/assets/img/DFKP0626.jpg";

//components
import LearnMoreButton from '~/components/_core/button/LearnMoreButton';


export default function HomeStaffSection(){

    const learnMoreUrl = env.NEXT_PUBLIC_APP_BASE_URL + "/certifications";

    return (
        <StyledContainer>
            {/* Blue Box */}
            <Box sx={{
            height: {xs: "100px", md: "200px"},
            width: "100%",
            padding: '0',
            margin: "0",
            backgroundColor: "primary.light"
            }}/>

            <StyledSection elevation={4}>
                <Grid container marginTop="-5rem" sx={{backgroundColor: "background.paper"}}>
                    <Grid item xs={12} md={6} padding={{xs: "0 1rem", md: "0 4rem"}}>
                        <StyledGridItem>
                            <Typography variant='h2' color="primary" textAlign="center">OUR STAFF</Typography>
                        </StyledGridItem>
                        <Divider/>
                        <StyledGridItem>
                            <Typography variant='body1' lineHeight="2rem">All our personnel are FAA certified A&P, FCC Qualified Technicians, industry trained ETOPS, RVSM and CAT III level 2 awareness trained.</Typography>
                        </StyledGridItem>
                        <StyledGridItem>
                            <LearnMoreButton color='primary' href={learnMoreUrl}/>
                        </StyledGridItem>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <StyledImageContainer>
                            <Image {...MainImage} alt='staff-image'/>
                        </StyledImageContainer>
                    </Grid>
                </Grid>
            </StyledSection>

        </StyledContainer>
    )
}



const StyledContainer = styled(Box)(({theme}) => ({
    width: "100%",

}));

const StyledSection = styled(Paper)(({theme}) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
    width: "1200px",

    "@media(max-width: 1200px)": {
        width: "100%",
        margin: "0",
    }
}))

const StyledGridItem = styled(Box)(({theme}) => ({
    padding: "2rem 0rem",
}))

const StyledImageContainer = styled(Box)(({theme}) => ({

    display: "flex",

    "img": {
        width: "100%",
        height: "400px",
        objectFit: "cover",
        display: "block",
    
        "@media(max-width: 1200px)": {
            height: "400px",
        },
    
        "@media(max-width: 600px)": {
            height: "auto",
        }
    }
}))

