import React, {useRef, useEffect} from 'react';
import { motion, useInView, useAnimation} from "framer-motion";


interface FramerFadeProps {
    children: React.ReactNode;
    delay: number;
    duration: number;
    isInView: boolean;
    xHidden?: number;
    xVisible?:number;
}

export default function FramerFade(props: FramerFadeProps) {

    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });
    const controls = useAnimation();

    useEffect(() => {
        if(props.isInView){
            if(isInView){
                controls.start("visible");
            }
            return;
        } 

        controls.start("visible");


    }, [controls, isInView])
  return (
    <motion.div
    ref={ref}
    animate={controls}
    initial="hidden"
    transition={{duration: props.duration, delay: props.delay}}
    variants={{
        hidden: {
            opacity: 0,
            x: props.xHidden? props.xHidden : 0
        },
        visible: {
            opacity: 1,
            x: props.xVisible? props.xVisible : 0
        }
    }}
    >
        {props.children}
    </motion.div>
  )
}
