import React, { useEffect, useMemo, useCallback } from 'react';
import Image, { StaticImageData } from 'next/image';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation, EffectFade } from "swiper";

//MUI
import styled from '@mui/system/styled';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';


interface ImageOptions {
    objectPosition?: string;
    objectFit?: string;
}

interface ImageItem {
    staticImage: StaticImageData,
    options?: ImageOptions;
}

export interface ImageCarouselProps {
    /**
     * imgs src as string array
     */
    items: ImageItem[]; 
}

export default function ImageCarouselV3(props: ImageCarouselProps){
    const isMobile = useMediaQuery('(max-width:1000px)');
    const isTablet = useMediaQuery('(max-width:1200px)');


    const items = useMemo(() => props.items, []);

    return (
        <StyledCarousel>
            <Swiper
            speed={1500}
            loop={true}
            autoplay={{
                delay: 5000,
                disableOnInteraction: false,
                }}
            pagination={{
            clickable: true,
            }}
            navigation={true}
            modules={[Pagination, Autoplay, Navigation]}
            >
                {items.map((item, index) => (
                    <SwiperSlide key={`carousel-image-${index}`} className="slide">
                        <StyledImageContainer {...item.options}>
                            <Image {...item.staticImage} alt={`carousel-image-${index}`} placeholder='blur' priority/>
                        </StyledImageContainer>
                    </SwiperSlide>
                ))}
            </Swiper>
        </StyledCarousel>
    )
}

const StyledCarousel = styled(Box)(({theme}) => ({
    width: "100%",
    height: "100%",
    overflow: "hidden",

    ":hover":{
        ".swiper-button-next":{
            display: "flex", 
            transition: "opacity ease-in-out 2s",
            opacity: 1,
        },
        ".swiper-button-prev":{
            display: "flex",
            transition: "opacity ease-in-out 2s",
            opacity: 1,
        }
    },



    ".swiper": {
        width: "100%",
        height: "100%",
        display: 'flex',
    },

    ".slide": {
        width: "100%",
        height: "100%",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    ".swiper-wrapper": {
        marginBottom: "1rem"
    },
    ".swiper-pagination": {
        
    },

    ".swiper-button-next": {
        color: theme.palette.grey[50],
        display: "none",
        opacity: 0,
        transition: "opacity ease-in-out 2s",
    },
    ".swiper-button-prev": {
        color: theme.palette.grey[50],
        display: "none",
        opacity: 0,
    },
    ".swiper-pagination-bullet": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.grey[50],
    },

    "@media (max-width: 600px)": {
        ".swiper-pagination": {
            
        },
    },
}))

const StyledImageContainer = styled('div')<ImageOptions>(({theme, objectPosition, objectFit}) => ({
    width: "100%",
    height: "100%",
    display: "flex",
    "img":{
        width: "100%",
        height: "inherit",
        objectFit: objectFit? objectFit: "cover",    
        objectPosition: objectPosition? objectPosition : "50% 50%"
    }
}))