import React from 'react';
import { env } from '~/env.mjs';


//Mui
import styled from '@mui/system/styled';
import useMediaQuery from '@mui/material/useMediaQuery';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


//Components
import FramerFade from "~/components/_core/framer/FramerFade";
import LearnMoreButton from '~/components/_core/button/LearnMoreButton';

export default function HomeMainSection(){

    const learnMoreUrl = env.NEXT_PUBLIC_APP_BASE_URL + "/about";

    return(
        <StyledContainer>
            <StyledSection>
                <StyledSectionContent>
                    <FramerFade delay={0} duration={1} isInView={true}>
                        <StyledSectionItem>
                            <StyledVortexText 
                            variant="h1" 
                            color="primary" 
                            textAlign="center" 
                            >vortex</StyledVortexText>

                            <StyledAviationText 
                            variant="h1" 
                            color="primary" 
                            textAlign="center" 
                            >aviation</StyledAviationText>

                        </StyledSectionItem>
                    </FramerFade>

                    <FramerFade delay={.1} duration={1} isInView={true}>
                        <Divider/>
                    </FramerFade>

                    
                    <FramerFade delay={.2} duration={1} isInView={true}>
                        <StyledSectionItem>
                            <Typography variant="h3" textAlign="center" color="primary" textTransform="uppercase" fontSize={{xs: "22px", md: "30px"}}>The number one aircraft line maintenance in Puerto Rico</Typography>
                        </StyledSectionItem>
                    </FramerFade>

                    <FramerFade delay={.3} duration={1} isInView={true}>
                        <StyledSectionItem sx={{padding: "0", "@media(max-width: 900px)": {padding: 0}}}>
                            <Typography variant="h5" textAlign="center" color="primary" textTransform="uppercase" fontSize={{xs: "20px", md: "26px"}}>repairs stations</Typography>
                            <Typography variant='h6' textAlign="center" color="primary">FAA: 6VMR174C</Typography>
                            <Typography variant='h6' textAlign="center" color="primary">FAA: 6VMD174C</Typography>
                            <Typography variant='h6' textAlign="center" color="primary">EASA: 145.6973</Typography>

                        </StyledSectionItem>
                    </FramerFade>

                    <FramerFade delay={.4} duration={1} isInView={true}>            
                        <StyledSectionItem>
                            <Typography variant="body1" lineHeight="2rem" >Vortex Aviation was founded in 1998, due to the need of a reliable source of maintenance for the commercial airlines that flight to Puerto Rico. We are conveniently located at the two major airports, Luis Muñoz Marin International (SJU), and Rafael Hernandez International Airport (BQN).</Typography>
                        </StyledSectionItem>
                    </FramerFade>

                    <FramerFade delay={.5} duration={1} isInView={true}>            
                        <StyledSectionItem>
                            <LearnMoreButton color='primary' href={learnMoreUrl}/>
                        </StyledSectionItem>
                    </FramerFade>

                </StyledSectionContent>
            </StyledSection>
        </StyledContainer>
    )
}

const StyledContainer = styled(Box)(({theme}) => ({
    padding: "2rem 0"
}));

const StyledSection = styled(Box)(({theme}) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
    width: "800px",

    "@media(max-width: 1000px)": {
        width: "100%",
    }
}))

const StyledSectionContent = styled(Box)(({theme}) => ({
    padding: "0 1rem",
}))

const StyledSectionItem = styled('div')(({theme}) => ({
    padding: "2rem 0rem",

    "@media(max-width: 900px)":{
        padding: "1.5rem 0rem",
    }
}))

const StyledVortexText = styled(Typography)(({theme}) => ({
    fontFamily: "Sea-Dog Expanded",
    fontSize: "68px",
    fontWeight: "400",
    lineHeight: "3.5rem",

    "@media(max-width: 900px)":{
        fontSize: "42px",
        lineHeight: "2rem",
    }


}))

const StyledAviationText = styled(Typography)(({theme}) => ({
    fontFamily: "Sea-Dog Expanded",
    fontSize: "60px",
    fontWeight: "400",
    lineHeight: "3.5rem",

    "@media(max-width: 900px)":{
        fontSize: "37px",
        lineHeight: "2rem",
    }
}))