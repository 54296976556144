import { type NextPage } from "next";

//Mui
import styled from '@mui/system/styled';
import Box from '@mui/material/Box';


//Components
import Layout from "~/components/_core/layout/Layout";
import HomeCarouselSection from "~/components/home/sections/HomeCarouselSection";
import HomeMainSection from "~/components/home/sections/HomeMainSection";
import HomeClientsSection from "~/components/home/sections/HomeClientsSection";
import HomeStaffSection from "~/components/home/sections/HomeStaffSection";

const HomePage: NextPage = () => {

  return (
    <Layout>
      <StyledContainer>
        <HomeCarouselSection/>
        <HomeMainSection/>
        <HomeStaffSection/>
        <HomeClientsSection/>
      </StyledContainer>  
    </Layout>
  );
};

const StyledContainer = styled(Box)(({theme}) => ({
  margin: "0",
  padding: "0",
}));





export default HomePage;
