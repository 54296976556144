import React from 'react';
import Image from "next/image";
import { env } from '~/env.mjs';

//Mui
import styled from '@mui/system/styled';
import useMediaQuery from '@mui/material/useMediaQuery';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

//Imgs
import JetBlueAirlinesLogo from '~/assets/logo/jetblue-airlines-logo.png';
import UnitedAirlinesLogo from '~/assets/logo/united-airlines-logo.png';
import IberiaAirlinesLogo from '~/assets/logo/iberia-airlines-logo.png';
import DeltaAirlinesLogo from '~/assets/logo/delta-airlines-logo.png';
import AmericanAirlinesLogo from '~/assets/logo/american-airlines-logo.png';
import SouthwestAirlinesLogo from '~/assets/logo/southwest-airlines-logo.png';
import FrontierAirlinesLogo from '~/assets/logo/frontier-airlines-logo.png';
import SpiritAirlinesLogo from '~/assets/logo/spirit-airlines-logo.png';
import AviancaAirlinesLogo from '~/assets/logo/avianca-airlines-logo.png';


//Components
import LearnMoreButton from '~/components/_core/button/LearnMoreButton';


const clientsImgs = [
    AmericanAirlinesLogo,
    DeltaAirlinesLogo,
    UnitedAirlinesLogo,
    JetBlueAirlinesLogo,
    IberiaAirlinesLogo,
    SouthwestAirlinesLogo,
    FrontierAirlinesLogo,
    SpiritAirlinesLogo,
    AviancaAirlinesLogo,
]

export default function HomeClientsSection(){

    const learnMoreUrl = env.NEXT_PUBLIC_APP_BASE_URL + "/clients";

    return (
        <StyledContainer>
            <StyledSection>
                <StyledSectionContent>
                    <StyledSectionItem>
                        <Typography variant='h2' color="primary">OUR CUSTOMERS</Typography>
                    </StyledSectionItem>
                    <Divider/>
                    <StyledSectionItem>
                        <Typography variant='body1' lineHeight="2rem">On our two main offices, we have covered almost all airlines. Since the beginning of Vortex Aviation Maintenance, we have given the most reliable and on time light maintenance services in the Island. Our personnel´s work speaks for itself. Since 1998 we have been running aircrafts services with excellent customer service and reliability.</Typography>
                    </StyledSectionItem>
                    <StyledSectionItem>
                        <LearnMoreButton color='primary' href={learnMoreUrl}/>
                    </StyledSectionItem>
                    <StyledSectionItem>
                       <Grid container spacing={{xs: 0, sm: 0, md: 5, lg: 10}} rowSpacing={2}>
                            {clientsImgs.map((item, index) => (
                                <Grid item xs={4} sm={4} md={4} lg={4} key={index}>
                                    <StyledCustomerLogoWrapper key={index}>
                                        <Image {...item} alt={`customer-logo-${index}`} priority/>
                                    </StyledCustomerLogoWrapper>
                                </Grid>
                            ))}
                       </Grid>
                    </StyledSectionItem>
                </StyledSectionContent>
            </StyledSection>
        </StyledContainer>
    )
}

const StyledContainer = styled(Box)(({theme}) => ({
    padding: "4rem 0"
}));

const StyledSection = styled(Box)(({theme}) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
    width: "1200px",

    "@media(max-width: 1250px)": {
        width: "100%",
    }
}))

const StyledSectionContent = styled(Box)(({theme}) => ({
    padding: " 0 1rem",
}))

const StyledSectionItem = styled(Box)(({theme}) => ({
    padding: "2rem 0",

    "@media(max-width: 900px)": {
        padding: "2rem 1rem",
    }
}))

const StyledCustomerLogoWrapper = styled(Box)(({theme}) => ({
    display: "flex",
    justifyContent: "start",
    
    "img":{
      display: "block",
      justifyContent: "center",
      width: "70%",
      height: "auto",
      objectFit: "fill",
      margin: "auto",
  
      "@media(max-width: 900px)": {
          width: "85%",
      }
    },
}))