import React from 'react';
import {motion} from "framer-motion"


//Mui
import styled from '@mui/system/styled';
import useMediaQuery from '@mui/material/useMediaQuery';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


//Images
import Image1 from '~/assets/img/jetblue-aguadilla-bmw.jpeg'
import Image2 from "~/assets/img/A350-landing-gear.jpg";
import Image3 from "~/assets/img/amerijet-eng-cowl.jpg";


import ImageCarouselV3 from '~/components/carousel/ImageCarouselV3';


export default function HomeCarouselSection(){

    return(
        <StyledContainer>
            <StyledCarouselSection>
                <ImageCarouselV3
                items={[
                    {staticImage: Image1},
                    {staticImage: Image2},
                    {staticImage: Image3},
                ]}
                />
            </StyledCarouselSection>

        </StyledContainer>
    )
}

const StyledContainer = styled(Box)(({theme}) => ({
    
}));

const StyledCarouselSection = styled(Box)(({theme}) => ({
    display: "flex",
    width: "1920px",
    height: "600px",
    margin: "auto",
    position: "relative",


    "@media(max-width: 1920px)": {
        width: "100%",
    },
    "@media(max-width: 1200px)": {
        width: "100%",
        height: "500px",
        margin: 0,
    },
    "@media(max-width: 900px)": {
        height: "300px",
    }
}));



